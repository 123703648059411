<template>
  <v-container class="tw-bg-blue-50 tw-w-full">
    <!-- Title Section -->
    <v-row class="d-flex justify-start mb-7 mt-3 ml-5">
      <h3 class="font-weight-bold tw-text-gray-700 tw-text-semibold">
        <slot name="title">{{ title }}</slot>
      </h3>
    </v-row>
    <br />

    <v-divider></v-divider>

    <!-- Description Section -->
    <p class="tw-text-gray-600 tw-mb-6">
      {{comment}}
    </p>
    <br />

    <!-- Photo Grid -->
    <v-row
      class="tw-grid sm:tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 xl:tw-grid-cols-4 tw-gap-6 ml-3 mr-3"
    >
      <div v-for="(photoSet, index) in checklist_photos" :key="index" class="">
        <!-- Label -->
        <p class="font-weight-bold text-gray-800 tw-mt-4">
          {{ photoSet.label }}
        </p>

        <!-- Image Section -->
        <div
          class="tw-relative tw-h-40 tw-w-full tw-mb-2 tw-rounded-lg tw-overflow-hidden"
        >
          <v-img
            :src="
              photoSet.photos[0]
                ? photoSet.photos[0].image_base_64
                : placeholder
            "
            class="tw-h-full tw-w-full tw-object-cover"
            :alt="`Image of ${photoSet.label}`"
          ></v-img>

          <!-- Actions Overlay -->
          <div
            class="tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-bg-black-50 tw-opacity-0 hover:tw-opacity-100 tw-transition tw-duration-300 tw-flex tw-justify-center tw-items-center tw-gap-4"
            aria-label="Actions"
          >
            <v-btn icon color="white" @click="viewPhoto(photoSet.photos)">
              <v-icon>mdi-eye</v-icon>
            </v-btn>
            <v-btn icon color="white" @click="editPhoto(photoSet)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </div>
        </div>

        <!-- Comment Section -->
        <p class="text-gray-600 text-sm tw-px-4 tw-mb-4">
          {{
            photoSet.photos[0]
              ? photoSet.photos[0].comment
              : 'No comment provided.'
          }}
        </p>
      </div>
    </v-row>

    <!-- Photo Viewer Modal -->
    <v-dialog v-model="isPhotoViewerOpen" max-width="600px">
      <v-card>
        <!-- Title -->
        <v-card-title class="text-h5">Photo Viewer</v-card-title>

        <!-- Image and Comment -->
        <v-card-text>
          <v-img
            :src="currentPhoto ? currentPhoto.image_base_64 : placeholder"
            class="rounded-lg"
            max-height="400px"
          ></v-img>
          <p class="text-gray-600 text-sm mt-4">
            {{ currentPhoto ? currentPhoto.comment : 'No comment provided.' }}
          </p>
        </v-card-text>

        <!-- Footer with Navigation and Close Button -->
        <v-card-actions>

          <!-- Spacer to push Close Button to the right -->
          <v-spacer></v-spacer>

          <!-- Close Button -->
          <v-btn color="primary" @click="isPhotoViewerOpen = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  props: {
    checklist_photos: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: 'Uploaded Photos',
    },
    comment: {
      type: String,
      default: 'Uploaded Photos',
    },
    placeholder: {
      type: String,
      default: 'placeholder.png',
    },
  },
  data() {
    return {
      isPhotoViewerOpen: false,
      currentPhoto: null,
      currentPhotoIndex: 0,
      photosInView: [],
      totalPhotos: 0,
    }
  },
  methods: {
    viewPhoto(photos) {
      // Open the first photo in the viewer
      this.photosInView = photos
      this.totalPhotos = photos.length
      this.currentPhotoIndex = 0
      this.currentPhoto = this.photosInView[this.currentPhotoIndex]
      this.isPhotoViewerOpen = true
    },
    navigatePhoto(direction) {
      if (direction === 'prev' && this.currentPhotoIndex > 0) {
        this.currentPhotoIndex--
      } else if (
        direction === 'next' &&
        this.currentPhotoIndex < this.totalPhotos - 1
      ) {
        this.currentPhotoIndex++
      }
      this.currentPhoto = this.photosInView[this.currentPhotoIndex]
    },

    editPhoto(photoSet) {
      if (photoSet.photos && photoSet.photos.length > 0) {
        const firstPhoto = photoSet.photos[0]
        const comment = firstPhoto?.comment || 'No comment provided'
        this.$emit('edit-photo', {
          label: photoSet.label,
          photos: photoSet.photos,
          comment: comment,
        })
      } else {
        this.$store.commit('SNACKBAR', {
          state: 'failed',
          message: 'No photos available for editing',
          variant: 'error',
        })
      }
    },
  },
}
</script>

<style scoped>
/* Grid styling */
.tw-grid {
  display: grid;
  gap: 24px;
}

/* Landscape image */
.tw-h-40 {
  height: 10rem;
}

/* Image overlay */
.tw-bg-black-50 {
  background-color: rgba(0, 0, 0, 0.5);
}

.tw-opacity-0 {
  opacity: 0;
}

.hover\:tw-opacity-100:hover {
  opacity: 1;
}

/* Card styles */
.tw-shadow-sm {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
</style>
