<template>
  <v-dialog
    v-model="localIsOpen"
    max-width="400px"
    class="tw-font-semibold tw-rounded-full"
  >
    <v-card class="tw-border tw-border-gray-300 tw-rounded-2xl">
      <!-- Modal Header -->
      <div
        class="tw-text-center tw-text-lg tw-font-semibold tw-text-primaryblue tw-py-4"
      >
        {{ localMode === 'edit' ? 'Edit Photo' : 'Upload Photo' }}
      </div>
      <v-divider></v-divider>

      <!-- Modal Body -->
      <v-card-text class="tw-px-6 tw-py-4">
        <!-- Photo Preview Section -->
        <div
          v-if="
            uploadedPhoto &&
            (uploadedPhoto.image_base_64 || uploadedPhoto.image_url)
          "
          class="tw-w-full tw-flex tw-justify-center tw-mb-4"
        >
          <v-img
            :src="uploadedPhoto.image_base_64 || uploadedPhoto.image_url"
            class="tw-rounded-lg tw-border tw-border-gray-300"
            height="180px"
            width="auto"
            :alt="
              localMode === 'edit'
                ? 'Edited Photo Preview'
                : 'Uploaded Photo Preview'
            "
          ></v-img>
        </div>
        <!-- No Photo Section -->
        <div v-else class="tw-text-center tw-mb-4">
          <p>No photo uploaded.</p>
          <v-btn
            color="primary"
            class="tw-mt-2"
            @click="openPhotoSelectionDialog"
          >
            Upload Photo
          </v-btn>
        </div>

        <!-- Action Links -->
        <div v-if="uploadedPhoto" class="tw-text-center tw-mb-4">
          <a
            href="#"
            @click.prevent="openPhotoSelectionDialog"
            class="tw-text-primaryblue tw-underline tw-font-medium tw-text-sm"
          >
            Change Photo
          </a>
          <span class="tw-mx-4 tw-text-gray-400"></span>
          <a
            href="#"
            @click.prevent="removePhoto"
            class="tw-text-red-500 tw-underline tw-font-medium tw-text-sm"
          >
            Remove Photo
          </a>
        </div>

        <!-- Label Display -->
        <div class="tw-mb-4">
          <p class="tw-text-gray-700 tw-text-base tw-font-semibold">
            {{ label }}
          </p>
        </div>

        <!-- Comment Section -->
        <div class="tw-mb-4" v-if="uploadedPhoto">
          <textarea
            class="tw-w-full tw-rounded-lg tw-bg-gray-100 tw-p-3 tw-text-gray-700 focus:tw-outline-none"
            v-model="photoDescription"
            :placeholder="
              localMode === 'edit'
                ? 'Edit your comment here...'
                : 'Enter a comment for this photo...'
            "
          ></textarea>
        </div>
      </v-card-text>

      <!-- Modal Footer -->
      <v-card-actions
        class="tw-flex tw-justify-between tw-items-center tw-px-6 tw-py-4"
      >
        <v-btn
          outlined
          color="primary"
          class="tw-px-6 tw-rounded-lg"
          @click="closeModal"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          class="tw-px-6 tw-rounded-lg"
          @click="handleAction"
          :disabled="!uploadedPhoto"
        >
          {{ localMode === 'edit' ? 'Save & Continue' : 'Add Photo Item' }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- Hidden File Input -->
    <input
      ref="fileInput"
      type="file"
      class="d-none"
      accept="image/*"
      :capture="photoInputMode === 'camera' ? 'environment' : undefined"
      @change="handleFileUpload"
    />

    <!-- Photo Selection Dialog -->
    <v-dialog
      v-model="isPhotoSelectionDialogOpen"
      max-width="400px"
      class="tw-rounded-lg tw-font-semibold"
    >
      <v-card class="tw-border tw-border-gray-200 tw-rounded-lg tw-shadow-md">
        <!-- Modal Header -->
        <div
          class="tw-text-center tw-font-bold tw-text-lg tw-text-primaryblue tw-pt-6"
        >
          Add Your Photo
        </div>
        <div class="tw-text-center tw-text-sm tw-text-gray-500 tw-mt-2 tw-mb-4">
          Choose how you want to add your photo
        </div>

        <!-- Modal Body -->
        <v-card-text class="tw-px-6 tw-py-6">
          <div class="tw-flex tw-justify-between tw-items-center">
            <!-- Upload Photo Option -->
            <div class="tw-flex tw-flex-col tw-items-center tw-w-1/2 tw-pr-2">
              <div
                class="tw-w-16 tw-h-16 tw-bg-primaryblue tw-rounded-full tw-flex tw-items-center tw-justify-center"
              >
                <v-icon large color="white">mdi-upload</v-icon>
              </div>
              <p class="tw-text-gray-700 tw-font-medium tw-mt-4">
                Upload Photo
              </p>
              <v-btn
                color="primary"
                class="tw-mt-4 tw-w-full tw-rounded-lg"
                @click="selectPhotoMode('upload')"
              >
                Upload Photo
              </v-btn>
            </div>

            <!-- Vertical Divider -->
            <div class="tw-border-l tw-border-gray-300 tw-h-full tw-mx-4"></div>

            <!-- Take Photo Option -->
            <div class="tw-flex tw-flex-col tw-items-center tw-w-1/2 tw-pl-2">
              <div
                class="tw-w-16 tw-h-16 tw-bg-primaryblue tw-rounded-full tw-flex tw-items-center tw-justify-center"
              >
                <v-icon large color="white">mdi-camera</v-icon>
              </div>
              <p class="tw-text-gray-700 tw-font-medium tw-mt-4">Take Photo</p>
              <v-btn
                color="primary"
                class="tw-mt-4 tw-w-full tw-rounded-lg"
                @click="selectPhotoMode('camera')"
              >
                Take Photo
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
export default {
  name: 'UploadPhotoModal',
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    photo: {
      type: [Object, Array],
      default: null,
    },
    mode: {
      type: String,
      default: 'upload',
    },
    autoOpenFilePicker: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      localIsOpen: this.isOpen,
      uploadedPhoto: null,
      photoDescription: '', // User-inputted comment
      isPhotoSelectionDialogOpen: false,
      localMode: this.mode, // Local copy of mode
      photoInputMode: this.localMode === 'edit' ? 'upload' : this.localMode,
    }
  },
  watch: {
    isOpen(newValue) {
      this.localIsOpen = newValue

      if (newValue) {
        this.initializeModal()
        if (this.localMode === 'edit') {
          if (
            this.photo &&
            (this.photo.image_base_64 || this.photo.image_url)
          ) {
            this.uploadedPhoto = JSON.parse(JSON.stringify(this.photo))
            this.photoDescription = this.photo.comment || ''
          } else {
            this.uploadedPhoto = null
            this.photoDescription = ''
          }
        } else {
          this.$nextTick(() => {
            this.triggerFileInput()
          })
        }
      } else {
        this.resetModal()
      }
    },
    mode(newValue) {
      this.localMode = newValue
    },
  },
  methods: {
    initializeModal() {
      this.localMode = this.mode
      this.photoInputMode =
        this.localMode === 'edit' ? 'upload' : this.localMode
      if (this.localMode === 'edit' && this.uploadedPhoto) {
        this.photoDescription = this.uploadedPhoto.comment || ''
      }
    },
    triggerFileInput() {
      this.$refs.fileInput.click()
    },
    handleFileUpload(event) {
      const file = event.target.files[0]
      const validTypes = ['image/jpeg', 'image/png']

      if (!file || !validTypes.includes(file.type)) {
        this.$emit('error', 'Invalid file type. Only JPEG or PNG are allowed.')
        return
      }

      const reader = new FileReader()
      reader.onload = (e) => {
        this.uploadedPhoto = {
          ...this.uploadedPhoto,
          image_base_64: e.target.result,
        }

        event.target.value = null
      }
      reader.readAsDataURL(file)
    },

    handleAction() {
      if (!this.uploadedPhoto) {
        this.$emit('error', 'Please upload or edit a photo before saving.')
        return
      }

      // Attach the comment to the uploadedPhoto
      this.uploadedPhoto.comment =
        this.photoDescription || 'No comment provided'

      if (this.localMode === 'edit') {
        this.$emit('photo-edited', {
          label: this.label,
          photo: this.uploadedPhoto,
        })
      } else {
        this.$emit('photo-added', {
          label: this.label,
          photos: [this.uploadedPhoto],
        })
      }

      this.resetModal()
    },

    removePhoto() {
      this.$emit('photo-removed', {
        label: this.label,
        photo: this.uploadedPhoto,
      })
      this.uploadedPhoto = null
      this.photoDescription = ''
    },
    resetModal() {
      this.localIsOpen = false
      this.uploadedPhoto = null
      this.photoDescription = ''
      this.isPhotoSelectionDialogOpen = false
      this.localMode = this.mode
      this.photoInputMode = null
    },
    closeModal() {
      this.resetModal()
      this.$emit('close')
    },
    // Methods for the photo selection dialog
    openPhotoSelectionDialog() {
      this.isPhotoSelectionDialogOpen = true
    },
    closePhotoSelectionDialog() {
      this.isPhotoSelectionDialogOpen = false
    },
    selectPhotoMode(selectedMode) {
      this.photoInputMode = selectedMode
      this.isPhotoSelectionDialogOpen = false

      // Trigger the file input
      this.$nextTick(() => {
        this.triggerFileInput()
      })
    },
  },
}
</script>

<style scoped>
.tw-text-primaryblue {
  color: #0056d2;
}
.tw-border-gray-300 {
  border-color: #d1d5db;
}
.tw-text-red-500 {
  color: #ef4444;
}
textarea {
  resize: none;
  min-height: 80px;
  line-height: 1.5;
  font-size: 14px;
}
.v-sheet.v-card {
  border-radius: 20px;
}
</style>
